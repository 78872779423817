import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { QUESTIONNAIRES_ROUTER_PARAM, TOKEN_PERSISTENCE_KEY } from 'src/app/constants';
import { PatientCredentialToken } from 'src/app/models/patient-credential-token';
import { PatientCredentialTokenApiService } from 'src/app/services/patient-credential-token-api.service';
import { PersistanceService } from 'src/app/services/persistance.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class StartComponent implements OnInit {

  constructor(private router: Router, private tokenService: PatientCredentialTokenApiService, private persistanceService: PersistanceService) {}

  ngOnInit() {
    this.validateTokenAndGetQuestionnaireId();
  }

  validateTokenAndGetQuestionnaireId() {
    const token = this.router.parseUrl(this.router.url).queryParamMap.get('token');

    this.tokenService.validateToken(token).pipe(
      take(1),
      ).subscribe((isTokenValid: boolean) => {
        if (isTokenValid) {
          this.tokenService.getTokenData(token).pipe(
            take(1),
          ).subscribe((tokenData: PatientCredentialToken) => {
            this.persistanceService.set(TOKEN_PERSISTENCE_KEY, tokenData);
            this.router.navigate([QUESTIONNAIRES_ROUTER_PARAM]);
          });
        } else {
          console.log('Invalid Token!, To use developer Token click icon: ');
        }
      });
  }

  handleNavigateWithParamsClick() {
    this.tokenService.getTokenData('QtyCwzAEb0gcl8GyLELS').pipe(
      take(1),
    ).subscribe((tokenData: PatientCredentialToken) => {
      const feToken: PatientCredentialToken = {
        ...tokenData,
        needsPatientPersonalData: true,
      };

      console.log(feToken);
      this.persistanceService.set(TOKEN_PERSISTENCE_KEY, feToken);
      this.router.navigate([QUESTIONNAIRES_ROUTER_PARAM]);
    });
  }
 }
