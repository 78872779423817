import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ButtonAnswersComponent } from './components/button-answers/button-answers.component';
import { ButtonComponent } from './components/button/button.component';
import { PersonalQuestionComponent } from './components/personal-question/personal-question.component';
import { QuestionDetailComponent } from './components/question-detail/question-detail.component';
import { QuestionnaireDetailComponent } from './components/questionnaire-detail/questionnaire-detail.component';
import { QuestionnaireListComponent } from './components/questionnaire-list/questionnaire-list.component';
import { SliderAnswersComponent } from './components/slider-answers/slider-answers.component';
import { StartComponent } from './components/start/start.component';
import { HasTokenGuard } from './services/has-token-guard.service';
import { PatientCredentialTokenApiService } from './services/patient-credential-token-api.service';
import { PersistanceService } from './services/persistance.service';
import { QuestionnairesApiService } from './services/questionnaires-api.service';
import { StartGuard } from './services/start-guard.service';

@NgModule({
   imports: [
      BrowserModule,
      AppRoutingModule,
      FormsModule,
      HttpClientModule,
      BrowserAnimationsModule,
   ],
   declarations: [
      AppComponent,
      QuestionnaireListComponent,
      QuestionnaireDetailComponent,
      QuestionDetailComponent,
      PersonalQuestionComponent,
      ButtonAnswersComponent,
      SliderAnswersComponent,
      ButtonComponent,
      StartComponent,
   ],
   providers: [
      {
         provide: QuestionnairesApiService,
         // useClass: MockQuestionnairesApiService,
         useClass: QuestionnairesApiService,
      },
      {
         provide: PatientCredentialTokenApiService,
         useClass: PatientCredentialTokenApiService,
      },
      HasTokenGuard,
      StartGuard,
      PersistanceService,
   ],
   bootstrap: [
      AppComponent,
   ],
})
export class AppModule { }
