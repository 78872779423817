import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Answer } from 'src/app/models/answer.model';
import { Choice } from 'src/app/models/choice.model';

@Component({
  selector: 'app-button-answers',
  templateUrl: './button-answers.component.html',
  styleUrls: ['./button-answers.component.css'],
})
export class ButtonAnswersComponent {

  @Input() options: Choice[];
  @Output() answer: EventEmitter<Answer> = new EventEmitter();

  handleButtonClick(choiceId: number) {
    const answer: Answer = {
      choiceId,
      answeredAt: new Date().toISOString(),
    };
    this.answer.emit(answer);
  }

}
