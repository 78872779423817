import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_BASE, TOKEN_PERSISTENCE_KEY } from '../constants';
import { Answer } from '../models/answer.model';
import { PatientCredentialToken } from '../models/patient-credential-token';
import { Questionnaire, QuestionnaireListItem } from '../models/questionnaire.model';

import { PersistanceService } from './persistance.service';

@Injectable({
  providedIn: 'root',
})
export class QuestionnairesApiService {

  constructor(public client: HttpClient, private persistanceService: PersistanceService) { }

  public getQuestionnaires(): Observable<QuestionnaireListItem[]> {
    return this.client.get<QuestionnaireListItem[]>(`${API_BASE}/questionnaires`);
  }

  public getQuestionnaire(questionnaireId: string): Observable<Questionnaire> {
    return this.client.get<Questionnaire>(`${API_BASE}/questionnaires/${questionnaireId}`);
  }

  public addResponseSet(answers: { [questionId: number]: Answer }, personalQuestionResponses: { [key: string]: string }): Observable<{}> {
    const credentialToken: PatientCredentialToken = this.persistanceService.get(TOKEN_PERSISTENCE_KEY);

    const responseSetBody = {
      answers,
      personalQuestionResponses,
    };

    return this.client.post<{}>(`${API_BASE}/responseSets/add/${credentialToken.id}`, responseSetBody);
  }
}
