import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { QuestionnaireDetailComponent } from './components/questionnaire-detail/questionnaire-detail.component';
import { QuestionnaireListComponent } from './components/questionnaire-list/questionnaire-list.component';
import { StartComponent } from './components/start/start.component';
import { QUESTIONNAIRE_ID_ROUTER_PARAM, QUESTIONNAIRES_ROUTER_PARAM, START_ROUTER_PARAM, TOKEN_PERSISTENCE_KEY } from './constants';
import { HasTokenGuard } from './services/has-token-guard.service';
import { StartGuard } from './services/start-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: START_ROUTER_PARAM,
    pathMatch: 'full',
  },
  {
    path: QUESTIONNAIRES_ROUTER_PARAM,
    canActivate: [HasTokenGuard],
    component: QuestionnaireListComponent,
  },
  {
    path: `${QUESTIONNAIRES_ROUTER_PARAM}/:${QUESTIONNAIRE_ID_ROUTER_PARAM}`,
    canActivate: [HasTokenGuard],
    component: QuestionnaireDetailComponent,
  },
  {
    path: `${START_ROUTER_PARAM}?${TOKEN_PERSISTENCE_KEY}=:${TOKEN_PERSISTENCE_KEY}`,
    canActivate: [StartGuard],
    component: StartComponent,
  },
  {
    path: START_ROUTER_PARAM,
    canActivate: [StartGuard],
    component: StartComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
