import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-personal-question',
  templateUrl: './personal-question.component.html',
  styleUrls: ['./personal-question.component.css'],
})
export class PersonalQuestionComponent {

  @Input() personalQuestionType: string;
  @Output() personalQuestionAnsweredClick: EventEmitter<string> = new EventEmitter();
  @Output() personalQuestionAnswersEditClick: EventEmitter<string> = new EventEmitter();

  lastName: string;
  firstName: string;
  birthday: string;
}
