import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { of } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { QUESTIONNAIRE_ID_ROUTER_PARAM, TOKEN_PERSISTENCE_KEY } from 'src/app/constants';
import { Answer } from 'src/app/models/answer.model';
import { ChoiceSet } from 'src/app/models/choice-set.model';
import { Question } from 'src/app/models/question.model';
import { Questionnaire } from 'src/app/models/questionnaire.model';
import { PersistanceService } from 'src/app/services/persistance.service';
import { QuestionnairesApiService } from 'src/app/services/questionnaires-api.service';

const activeStyle = style({
  transform: 'translateX(0%)',
});
const inactiveStyle = style({
  transform: 'translateX(100%)',
});

const timing = '.5s ease-in-out';

@Component({
  selector: 'app-questionnaire-detail',
  templateUrl: './questionnaire-detail.component.html',
  styleUrls: ['./questionnaire-detail.component.css'],
  animations: [
    trigger('slideInChild', [
      transition('void => *', [inactiveStyle, animate(timing, activeStyle)]),
      transition(':increment', [inactiveStyle, animate(timing, activeStyle)]),
      transition(':decrement', [activeStyle, animate(timing, inactiveStyle)]),
    ]),
  ],
})
export class QuestionnaireDetailComponent implements OnInit {
  questionnaire: Questionnaire;
  currentlyVisibleQuestionIndex: number;
  currentlyVisibleQuestion: Question;
  currentlyVisibleQuestionChoiceSet: ChoiceSet;
  answeredLastQuestion = false;

  personalQuestions = ['lastName', 'firstName', 'birthday', 'confirmation'];
  currentPersonalQuestionIndex = 0;
  answeredAllPersonalQuestions = false;
  personalQuestionResponses: {[key: string]: string} = {};

  successfullySaved = false;
  saveFailed = false;
  answers: { [choiceId: number]: Answer };

  constructor(
    private route: ActivatedRoute,
    private questionnairesApiService: QuestionnairesApiService,
    private persistance: PersistanceService,
  ) { }

  ngOnInit() {
    this.loadQuestionnaire();
    this.checkPersonalData();
  }

  checkPersonalData() {
    // TODO: if user is new, ask for personal data (last name, first name, birthday).
    const userNeedsToAnswerPersonalQuestions = this.persistance.get(TOKEN_PERSISTENCE_KEY).needsPatientPersonalData;

    if (userNeedsToAnswerPersonalQuestions) {
      this.answeredAllPersonalQuestions = false;
    } else {
      this.answeredAllPersonalQuestions = true;
    }
  }

  loadQuestionnaire() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.questionnairesApiService.getQuestionnaire(params.get(QUESTIONNAIRE_ID_ROUTER_PARAM)).pipe(
        take(1),
      ).subscribe((fetchedQuestionnaire: Questionnaire) => {

        this.questionnaire = fetchedQuestionnaire;
        this.prepareAnswers(fetchedQuestionnaire);

        if (!this.currentlyVisibleQuestionIndex) {
          this.currentlyVisibleQuestionIndex = 0;
          this.setCurrentlyVisibleQuestion(0);
        }

      });
    });
  }

  prepareAnswers(questionnaire: Questionnaire) {
    const questions = questionnaire.questions;
    this.answers = questions.reduce((obj, question) => {
      obj[question.id] = undefined;
      return obj;
    }, {});
  }

  setAnswer(questionId: number, answer: Answer | undefined) {
    this.answers[questionId] = answer;
  }

  goToNextQuestion() {
    const lastQuestion = this.currentlyVisibleQuestionIndex === this.questionnaire.questions.length - 1;
    if (!lastQuestion) {
      this.currentlyVisibleQuestionIndex++;
      this.setCurrentlyVisibleQuestion(this.currentlyVisibleQuestionIndex);
    } else {
      this.answeredLastQuestion = true;
    }
  }

  setCurrentlyVisibleQuestion(index: number) {
    this.currentlyVisibleQuestion = this.questionnaire.questions[index];
    this.currentlyVisibleQuestionChoiceSet = this.questionnaire.choiceSets.find(
      (choiceSet: ChoiceSet) => choiceSet.id === this.currentlyVisibleQuestion.choiceSetId
    );
  }

  onChoiceButtonClick(questionId: number, answer: Answer | undefined) {
    this.setAnswer(questionId, answer);
    this.goToNextQuestion();
  }

  onPersonalQuestionAnswered(personalDataInput: string) {
    const key: string = this.personalQuestions[this.currentPersonalQuestionIndex];
    this.personalQuestionResponses[key] = personalDataInput;

    if (this.currentPersonalQuestionIndex < this.personalQuestions.length - 1) {
      this.currentPersonalQuestionIndex++;
    } else {
      this.answeredAllPersonalQuestions = true;
      delete this.personalQuestionResponses.confirmation;
    }
  }

  onPersonalQuestionAnswersEdit() {
    this.currentPersonalQuestionIndex = 0;
  }

  onSaveButtonClick() {
    let personalDataPayload: {[key: string]: string};

    if (Object.keys(this.personalQuestionResponses).length > 0) {
      personalDataPayload = this.personalQuestionResponses;
    } else {
      personalDataPayload = undefined;
    }

    this.questionnairesApiService.addResponseSet(this.answers, personalDataPayload).pipe(
      tap(() => this.finishQuestionnaire()),
      catchError(error => {
        this.saveFailed = true;
        return of(error); // TODO: Do proper error handling in Interceptor when BE is present
      }),
      take(1),
    ).subscribe();
  }

  finishQuestionnaire() {
    this.successfullySaved = true;
    this.persistance.delete(TOKEN_PERSISTENCE_KEY);
  }
}
