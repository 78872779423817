import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Answer } from 'src/app/models/answer.model';
import { ChoiceSet } from 'src/app/models/choice-set.model';
import { QuestionType } from 'src/app/models/question-type.model';
import { Question } from 'src/app/models/question.model';

@Component({
  selector: 'app-question-detail',
  templateUrl: './question-detail.component.html',
  styleUrls: ['./question-detail.component.css'],

})
export class QuestionDetailComponent {

  @Input() question: Question;
  @Input() choiceSet: ChoiceSet;
  @Output() setAnswer: EventEmitter<Answer | undefined> = new EventEmitter();

  QuestionType = QuestionType;

  onAnswer(answer: Answer | undefined) {
    this.setAnswer.emit(answer);
  }

}
