import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_BASE } from '../constants';
import { PatientCredentialToken } from '../models/patient-credential-token';

@Injectable({
  providedIn: 'root',
})
export class PatientCredentialTokenApiService {

  constructor(public client: HttpClient) { }

  public validateToken(token: string): Observable<boolean> {
    return this.client.get<boolean>(`${API_BASE}/token/validate/${token}`);
  }

  public getTokenData(token: string): Observable<PatientCredentialToken> {
    return this.client.get<PatientCredentialToken>(`${API_BASE}/token/data/${token}`);
  }
}
