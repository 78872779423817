import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Answer } from 'src/app/models/answer.model';
import { Choice } from 'src/app/models/choice.model';

@Component({
  selector: 'app-slider-answers',
  templateUrl: './slider-answers.component.html',
  styleUrls: ['./slider-answers.component.scss'],
})
export class SliderAnswersComponent {

  _options: Choice[];
  @Input()
  set options(options: Choice[]) {
    this.selectedIndex = Math.floor(options.length / 2);
    this.max = options.length - 1;
    this._options = options;
  }
  get options(): Choice[] {
    return this._options;
  }

  @Output() answer: EventEmitter<Answer> = new EventEmitter();

  sliderIsTouched = false;
  selectedIndex = 0;
  max = 0;

  onSliderValueChange(value: number) {
    this.sliderIsTouched = true;
    this.selectedIndex = Number(value);
  }

  handleSliderValueConfirm(value: number | undefined) {
    if (value === undefined) {
      this.answer.emit(undefined);
    } else {
      const selectedChoice: Choice = this.options[value];
      const answer: Answer = {
        choiceId: selectedChoice.id,
        answeredAt: new Date().toISOString(),
      };
      this.answer.emit(answer);
    }
    this.selectedIndex = Math.floor(this.options.length / 2);
    this.sliderIsTouched = false;
  }
}
